import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ClipboardIcon, CheckIcon } from "@heroicons/react/24/outline";

function ChatMessage({ message }) {
  // Determine if message is from the user or the assistant (bot)
  const isUser = message.party === "user";

  // Ensure the message content is a string
  const originalText =
    typeof message.message === "string" ? message.message : "";

  // --- Debug: log the original text to see exactly how many backslashes are present ---
  console.log("=== Original messageText ===");
  console.log(originalText);

  // Let's try simpler replacements with replaceAll()
  // Convert "\[ ... \]" to "$$ ... $$" for block math
  // Convert "\( ... \)" to "$ ... $" for inline math
  let processedMessage = originalText;

  // 1) Replace all occurrences of "\[" with "$$"
  processedMessage = processedMessage.replaceAll("\\[", "$$");
  // 2) Replace all occurrences of "\]" with "$$"
  processedMessage = processedMessage.replaceAll("\\]", "$$");
  // 3) Replace all occurrences of "\(" with "$"
  processedMessage = processedMessage.replaceAll("\\(", "$");
  // 4) Replace all occurrences of "\)" with "$"
  processedMessage = processedMessage.replaceAll("\\)", "$");

  // --- Debug: log the transformed text ---
  console.log("=== Processed messageText ===");
  console.log(processedMessage);

  // -- CodeBlock for syntax highlighting + copy button
  const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || "");
    const code = String(children).replace(/\n$/, "");
    const language = match ? match[1] : "Code";
    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    };

    // If it's a fenced code block, highlight it:
    if (!inline && match) {
      return (
        <>
          <div className="flex items-center justify-between bg-gray-200 text-slate-600 px-4 py-2 rounded-t-lg mt-4">
            <span className="text-sm font-semibold capitalize">{language}</span>
            <button
              onClick={handleCopyClick}
              className="bg-gray-200 p-1 rounded-md hover:bg-gray-100 focus:outline-none"
              aria-label="Copy code to clipboard"
            >
              {copied ? (
                <CheckIcon className="w-5 h-5 text-slate-600" />
              ) : (
                <ClipboardIcon className="w-5 h-5 text-slate-600" />
              )}
            </button>
          </div>
          <SyntaxHighlighter
            style={vs}
            language={language}
            customStyle={{
              backgroundColor: "#F9F9F9",
              padding: "16px",
              borderRadius: "0 0 8px 8px",
              margin: "0",
              overflowX: "auto",
            }}
            className="w-full mb-4"
            {...props}
          >
            {code}
          </SyntaxHighlighter>
        </>
      );
    } else {
      // Otherwise, inline code
      return (
        <code className={`${className} bg-gray-100 px-1 rounded`} {...props}>
          {children}
        </code>
      );
    }
  };

  return (
    <div
      className={`mb-4 w-full flex ${isUser ? "justify-end" : "justify-start"}`}
    >
      <div
        className={`rounded-lg px-4 py-2 ${
          isUser
            ? "bg-gray-300 text-black self-end max-w-3/4"
            : "bg-white text-gray-800 self-start w-full"
        }`}
      >
        {isUser ? (
          // Plain text if it's the user's message
          <p className="text-black">{originalText}</p>
        ) : (
          // Markdown rendering for the assistant's message
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={{
              code: CodeBlock,
              // Table styling
              table({ children, ...props }) {
                return (
                  <div className="overflow-x-auto my-4">
                    <table
                      className="min-w-full table-auto border-collapse border border-gray-300"
                      {...props}
                    >
                      {children}
                    </table>
                  </div>
                );
              },
              thead({ children, ...props }) {
                return (
                  <thead className="bg-gray-100" {...props}>
                    {children}
                  </thead>
                );
              },
              tbody({ children, ...props }) {
                return <tbody {...props}>{children}</tbody>;
              },
              th({ children, ...props }) {
                return (
                  <th
                    className="border border-gray-300 px-4 py-2 text-left font-medium text-gray-900"
                    {...props}
                  >
                    {children}
                  </th>
                );
              },
              td({ children, ...props }) {
                return (
                  <td
                    className="border border-gray-300 px-4 py-2 text-left"
                    {...props}
                  >
                    {children}
                  </td>
                );
              },
              // Headings styling
              h1({ children, ...props }) {
                return (
                  <h1 className="text-2xl font-bold mt-4 mb-2" {...props}>
                    {children}
                  </h1>
                );
              },
              h2({ children, ...props }) {
                return (
                  <h2 className="text-xl font-semibold mt-3 mb-2" {...props}>
                    {children}
                  </h2>
                );
              },
              h3({ children, ...props }) {
                return (
                  <h3 className="text-lg font-semibold mt-3 mb-1" {...props}>
                    {children}
                  </h3>
                );
              },
              // Blockquote styling
              blockquote({ children, ...props }) {
                return (
                  <blockquote
                    className="border-l-4 border-blue-500 pl-4 text-gray-600 italic my-4"
                    {...props}
                  >
                    {children}
                  </blockquote>
                );
              },
              // Lists
              ul({ children, ...props }) {
                return (
                  <ul className="list-disc list-inside ml-4 mb-4" {...props}>
                    {children}
                  </ul>
                );
              },
              ol({ children, ...props }) {
                return (
                  <ol className="list-decimal list-inside ml-4 mb-4" {...props}>
                    {children}
                  </ol>
                );
              },
              li({ children, ...props }) {
                return (
                  <li className="ml-6" {...props}>
                    {children}
                  </li>
                );
              },
              // Paragraph
              p({ children, ...props }) {
                return (
                  <p className="mb-4" {...props}>
                    {children}
                  </p>
                );
              },
              // Links
              a({ children, ...props }) {
                return (
                  <a
                    className="text-blue-500 underline hover:text-blue-600"
                    {...props}
                  >
                    {children}
                  </a>
                );
              },
            }}
          >
            {processedMessage}
          </ReactMarkdown>
        )}
      </div>
    </div>
  );
}

export default ChatMessage;
